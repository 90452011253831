export const apiPath = 'https://t-api.s.coursio.com/';
export const basePath = '/';

const applyBasePathTo = (path) => `${basePath}${path}`;
const applyApiPathTo = ({ path }) => {
  switch (path) {
    case 'api/dashboard':
    case 'api/auth':
    case 'api/course':
    case 'api/settings':
    case 'api/upload/plain':
      return `${apiPath}${path}`;
    default:
      break;
  }
};

export const logout = applyBasePathTo('logout');

// public
export const login = applyBasePathTo('login');
export const setUpPassword = applyBasePathTo('set-up');

// private
export const dashboard = applyBasePathTo('dashboard');
export const settings = applyBasePathTo('settings');

// api
export const authenticationApi = () => applyApiPathTo({ path: 'api/auth' });
export const dashboardApi = () => applyApiPathTo({ path: 'api/dashboard' });
export const settingsApi = () => applyApiPathTo({ path: 'api/settings' });
export const courseApi = () => applyApiPathTo({ path: 'api/course' });
export const plainApi = () => applyApiPathTo({ path: 'api/upload/plain' });
