// import { push } from 'connected-react-router';
import types from '../actions/auth/types';
// import { dashboard } from '../constants/urls';

const auth = (store) => (next) => (action) => {
  if (action.type === types.AUTHENTICATION_SUCCESS) {
    if (!action.payload.isError) {
      // store.dispatch(push(dashboard));
    }
  }

  // Not sure what the below code was supposed to do? It destroys the functionality of
  // being able to navigate through the browsers adress bar.

  // if (action.type === types.STATUS_SUCCESS) {
  //   if (action.payload.data.user) {
  //     store.dispatch(push('/dashboard'));
  //   }
  // }
  next(action);
};

export default auth;
