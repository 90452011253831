import types from '../../actions/auth/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SUCCESS:
      return { user: action.payload.data.user };
    case types.REQUEST_NEW_PASS_SUCCESS:
      return { ...state, requestedPassSuccess: true };
    case types.REQUEST_NEW_PASS_FAIL:
      return { ...state, requestedPassSuccess: false };
    case types.STATUS_SUCCESS:
      return { ...state, status: action.payload, statusType: types.STATUS_SUCCESS };
    case types.STATUS_FAIL:
      return { ...state, status: action.payload, statusType: types.STATUS_FAIL };
    default:
      return state;
  }
};
