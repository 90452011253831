import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
  // Redirect, Switch,
  Redirect,
  Switch,
  Route
} from 'react-router-dom';
import authenticationActions from '../actions/auth';
// import LoginContainer from '../containers/login';
import {
  // login, dashboard, settings,
  setUpPassword } from '../constants/urls';
import NotFound from '../components/notFound';
// import Header from '../containers/header';
// import DashboardContainer from '../containers/dashboard';
// import SettingsContainer from '../containers/settings';
// import PrivateRoute from './privateRoute';
import TermsConditionsContainer from '../containers/termsConditions';
import SetupPasswordContainer from '../containers/setupPassword';

class App extends Component {
  componentDidMount() {
    // const { onAppInit } = this.props;

    // onAppInit();
  }

  render() {
    // const { userStatus, user, statusType } = this.props;
    return (
      <>
        {/*<Header props={this.props} />*/}
        {/*<Switch>*/}
        {/*  <PrivateRoute*/}
        {/*    statusType={statusType}*/}
        {/*    auth={userStatus}*/}
        {/*    user={user}*/}
        {/*    component={DashboardContainer}*/}
        {/*    path={dashboard}*/}
        {/*  />*/}
        {/*  <PrivateRoute*/}
        {/*    statusType={statusType}*/}
        {/*    auth={userStatus}*/}
        {/*    user={user}*/}
        {/*    component={SettingsContainer}*/}
        {/*    path={settings}*/}
        {/*  />*/}
        {/*  <Route exact component={LoginContainer} path={login} />*/}
        <Switch>
          <Route exact component={TermsConditionsContainer} path="/:id" />
          <Route component={SetupPasswordContainer} path={`${setUpPassword}/:id`} />
          <Route component={NotFound} />
        </Switch>
        {/*<Redirect to={login} />*/}
        {/*</Switch>*/}
      </>
    );
  }
}

App.propTypes = {
  onAppInit: PropTypes.func.isRequired,
  userStatus: PropTypes.oneOfType([PropTypes.object]),
  user: PropTypes.oneOfType([PropTypes.object]),
  statusType: PropTypes.string,
};

App.defaultProps = {
  userStatus: null,
  user: null,
  statusType: null,
};

const mapStateToProps = (state) => ({
  statusType: state.authentication.statusType,
  userStatus: state.authentication.status,
  user: state.authentication.user,
});

const mapDispatchToProps = {
  ...authenticationActions,
};

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(App));
