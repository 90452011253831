import types from '../../actions/termsConditions/types';

const initialState = {};

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_TERMS_SUCCESS:
      const termsData = action.payload.data;
      return { ...state, termsData  };
    case types.ACCEPT_TERMS_FAIL:
    case types.CREATE_USER_FAIL: 
    case types.LOAD_TERMS_FAIL:
      return { ...state, status: {...action} };
    default:
      return state;
  }
};
