import types from './types';

const url = 'https://api-3.coursio.com/api/business-invite'; // For prod | Move to constants
// const url = 'https://t-api.s.coursio.com/api/business-invite'; // For test

const loadTerms = (code) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'loadTerms',
        data: {
          code,
        },
      }),
    };
    const response = await fetch(url, options);

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }
    await dispatch({
      type: types.LOAD_TERMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_TERMS_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const acceptTerms = ({ code, password, version }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'accept',
        data: {
          code,
          password,
          version,
        },
      }),
    };
    const response = await fetch(url, options);

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }
    await dispatch({
      type: types.ACCEPT_TERMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.ACCEPT_TERMS_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const createUser = ({ code, password }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'createUser',
        data: {
          code,
          password,
        },
      }),
    };
    const response = await fetch(url, options);

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }
    await dispatch({
      type: types.CREATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.CREATE_USER_FAIL,
      payload: error.message,
      error: true,
    });
  }
};


export default {
  loadTerms,
  acceptTerms,
  createUser,
};
