import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import authentication from './auth';
import dashboard from './dashboard';
import settings from './settings';
import termsConditions from './termsConditions';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  authentication,
  dashboard,
  settings,
  termsConditions,
});

export default appReducer;
