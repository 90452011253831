import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import history from './helpers/history';
import store from './store';

import App from './routes';
import './index.css';

const rootElement = document.getElementById('root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </ConnectedRouter>
  </Provider>,
  rootElement,
);
