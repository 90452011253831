import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SetUpPassword from '../../components/setupPassword';
import termsConditionsActions from '../../actions/termsConditions';

// import './styles.scss';

const SetUpPasswordContainer = ({ history, termsData, loadTerms, requestedPassSuccess, createUser , status}) => (
  <SetUpPassword
    loadTerms={loadTerms}
    createUser={createUser}
    termsData={termsData}
    history={history}
    status={status}
    requestedPassSuccess={requestedPassSuccess}
  />
);

SetUpPasswordContainer.propTypes = {
  termsData: PropTypes.object.isRequired,
  loadTerms: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  requestedPassSuccess: PropTypes.bool,
};

SetUpPasswordContainer.defaultProps = {
  requestedPassSuccess: true,
  status: {}
};

const mapStateToProps = (state) => ({
  termsData: state.termsConditions.termsData,
  requestedPassSuccess: state.termsConditions.requestedPassSuccess,
  status: state.termsConditions.status
});

const mapDispatchToProps = {
  ...termsConditionsActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetUpPasswordContainer);
