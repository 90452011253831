import React, { Component } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import logoImg from '../../assets/images/coursio-gray.png';
import poweredByImg from '../../assets/images/Poweredby.png';
import { withSnackbar } from 'notistack';
import NotFound from '../notFound';
import Spinner from '../spinner';


import './styles.scss';

const INVITE_CODE_LENGTH = 7;


class SetUpPassword extends Component {
  constructor(props) {
    super(props);
    this.passRef = React.createRef();
    this.state = {
      open: false,
      message: '',
      loading: false
    }
  };

  async componentDidMount() {
    const { loadTerms } = this.props;
    //in case of dataTerms.status <2 we should redirect to first page
    try {
      await loadTerms(this.getCode());
      this.setState({ loading: true })
    } catch (err) {
      console.log('Error:', err);
    } finally {
      this.setState({ loading: false })
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ open: false });
  };


  getCode = () => {
    const { history } = this.props;
    return history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];
  }

  handleAccept = async () => {
    const { createUser, requestedPassSuccess } = this.props;

    if (this.passRef.current.value.length < 8) {
      this.setState({ open: true, message: 'Password should be 8 characters at least!' })
      return;
    }
    await createUser({ code: this.getCode(), password: this.passRef.current.value });
    
    if(this.props.status.error) this.setState({ open: true, message: this.props.status.payload });
    else if (requestedPassSuccess) {
      this.setState({ open: true, message: 'Done! Your acc will be activated in near future' });
    } else {
      this.setState({ open: true, message: 'Business invite has already been accepted' });
    }
  }

  render() {
    const { termsData } = this.props;

    if (this.state.loading) {
      return <Spinner />
    }

    if (!termsData && this.getCode().length < INVITE_CODE_LENGTH) {
      return <NotFound />;
    }

    return (
      <div className="set-pass-container">
        <div className="set-pass-container-header">
          <div className="logo-container">
            <img src={logoImg} alt="logo"/>
          </div>
        </div>

        <div className="info-container">
          <div className="info-container-text">
            <div className="personal-info">
              <p><b>{termsData && termsData.name}</b> ({termsData && termsData.email})</p>
            </div>
            <p>Thank you for taking the time to accept our terms & conditions, a Coursio representative will contact you shortly to kickstart your education business journey!</p>
            <p>{"// Coursio Crew"}</p> 
            {/* For now rmeoving the pasword request until we decide how we will do it */}
            {/* {termsData && termsData.status <= 3 && <div>
              <p className="add-text">Please, for us to be able to create an account for your business, we need you to set up a password <span>(min number of characters 8)</span></p>
              <div className="save-password">
              <TextField
                inputRef={this.passRef}
                autoFocus
                margin="dense"
                id="pass"
                label="Password"
                type="password"
                fullWidth
              />
              <Button onClick={this.handleAccept} color="primary" className="set-up-btn">
                Save
              </Button>
              </div>
            </div>} */}
          </div>
        </div>

        <div className="set-pass-container-footer">
          <div className="logo-container">
            <img src={poweredByImg} alt="poweredBy"/>
            <img src={logoImg} alt="logo"/>
          </div>
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={this.state.message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    )
  }
}

export default withSnackbar(SetUpPassword);
