import uploadTypes from '../../actions/upload/types';
import dashboardTypes from '../../actions/dashboard/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboardTypes.DASHBOARD_SUCCESS:
      return { ...state, courses: action.payload.data };
    case dashboardTypes.DASHBOARD_FAIL:
      return { ...state, ...null };
    case uploadTypes.UPLOAD_SUCCESS:
      return { ...state, file: action.payload.data[0].file };
    case dashboardTypes.COURSE_DUPLICATE_SUCCESS:
      return { ...state, duplicatedCourse: action.payload.data };
    case dashboardTypes.READER_MODE:
      return { ...state, readerMode: true };
    case dashboardTypes.BUILDER_MODE:
      return { ...state, readerMode: false };
    default:
      return state;
  }
};
