import React, { Component } from 'react';
import CoursioLogo from '../coursioLogo';

import './styles.scss';

class NotFound extends Component {


  render() {
    return (
      <div className="not-found-container">
        <div className="error-wrapper">
          <CoursioLogo initialFill="#00c9cc" size={100} width="48px" />
          <p>404: Page not found.</p>
        </div>
      </div>
    );
  }
}

export default NotFound;
