import React, { Component, PropTypes } from 'react';

let colors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B'
];

let order = [1, 2, 3, 4];

export default class CoursioLogo extends Component {
  static propTypes = {
    // size: PropTypes.any,
    // circleRadius: PropTypes.number,
    // initialFill: PropTypes.string
  };

  static defaultProps = {
    initialFill: '#FFFFFF',
    size: 100,
    circleRadius: 10
  };

  constructor() {
    super(...arguments);
    this.state = {
      fill: this.props.initialFill,
      sizeDelta: 0
    };
    this.currentOrder = [];
  }

  add(move) {
    this.currentOrder.push(move);
    if (this.currentOrder.length > 4) {
      this.currentOrder.shift();
    }
    let correct = true;
    if (this.currentOrder.length === 4) {
      this.currentOrder.forEach((move, index) => {
        if (order[index] !== move) {
          correct = false;
        }
      });
    } else {
      correct = false;
    }
    if (correct && !this._interval) {
      this._interval = setInterval(() => {
        let fill = colors[Math.floor(Math.random() * colors.length)];
        let sizeDelta = 1 - this.state.sizeDelta;
        this.setState({ fill, sizeDelta });
      }, 1000);
    }
  }

  render() {
    let {
        size,
        circleRadius,
        initialFill,
        width,
        className,
        ...props
      } = this.props,
      logoSize = size - circleRadius * 2,
      { fill, sizeDelta } = this.state,
      x1 = 0,
      y1 = 0,
      x2 = logoSize / 2,
      y2 = logoSize / 4,
      x3 = logoSize,
      y3 = logoSize / 2,
      x4 = logoSize / 2,
      y4 = logoSize * 0.75,
      x5 = 0,
      y5 = logoSize;

    width = width || logoSize;

    return (
      <svg
        className={className}
        viewBox={'0 0 ' + size + ' ' + size}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={width}
        {...props}
      >
        <g style={{ transition: 'fill 1s ease' }} fill={fill}>
          <rect
            rect
            x={x1}
            y={y1}
            width={circleRadius * 2}
            height={logoSize / 2 + circleRadius * 2}
            rx={circleRadius}
            ry={circleRadius}
            onMouseLeave={this.add.bind(this, 0)}
          />
          <circle
            style={{ transition: 'all 1s ease' }}
            r={circleRadius - sizeDelta * 5}
            cx={circleRadius + x2}
            cy={circleRadius + y2}
            onMouseLeave={this.add.bind(this, 1)}
          />
          <circle
            style={{ transition: 'all 1s ease' }}
            r={circleRadius - sizeDelta * 5}
            cx={circleRadius + x3}
            cy={circleRadius + y3}
            onMouseLeave={this.add.bind(this, 2)}
          />
          <circle
            style={{ transition: 'all 1s ease' }}
            r={circleRadius - sizeDelta * 5}
            cx={circleRadius + x4}
            cy={circleRadius + y4}
            onMouseLeave={this.add.bind(this, 3)}
          />
          <circle
            style={{ transition: 'all 1s ease' }}
            r={circleRadius - sizeDelta * 5}
            cx={circleRadius + x5}
            cy={circleRadius + y5}
            onMouseLeave={this.add.bind(this, 4)}
          />
        </g>
      </svg>
    );
  }
}
