import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import TermsConditions from '../../components/termsConditions';
import termsConditionsActions from '../../actions/termsConditions';

const TermsConditionsContainer = ({ termsData, loadTerms, acceptTerms, history, status }) => (
  <TermsConditions
    termsData={termsData}
    loadTerms={loadTerms}
    acceptTerms={acceptTerms}
    history={history}
    status={status}
  />
);

TermsConditionsContainer.propTypes = {
  loadTerms: PropTypes.func.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  termsData: PropTypes.object.isRequired,
  status: PropTypes.object.isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

TermsConditionsContainer.defaultProps = {
  termsData: {},
  status: {},
};
const mapStateToProps = (state) => ({
  termsData: state.termsConditions.termsData,
  status: state.termsConditions.status
});

const mapDispatchToProps = {
  ...termsConditionsActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsConditionsContainer);
