export default Object.freeze({
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_FAIL: 'DASHBOARD_FAIL',
  COURSE_UPDATE_SUCCESS: 'COURSE_UPDATE_SUCCESS',
  COURSE_UPDATE_FAIL: 'COURSE_UPDATE_FAIL',
  COURSE_DELETE_SUCCESS: 'COURSE_DELETE_SUCCESS',
  COURSE_DELETE_FAIL: 'COURSE_DELETE_FAIL',
  COURSE_CREATE_SUCCESS: 'COURSE_CREATE_SUCCESS',
  COURSE_CREATE_FAIL: 'COURSE_CREATE_FAIL',
  COURSE_DUPLICATE_SUCCESS: 'COURSE_DUPLICATE_SUCCESS',
  COURSE_DUPLICATE_FAIL: 'COURSE_DUPLICATE_FAIL',
  READER_MODE: 'READER_MODE',
  BUILDER_MODE: 'BUILDER_MODE',
});
