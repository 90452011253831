import settingsTypes from '../../actions/settings/types';
import uploadTypes from '../../actions/upload/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case settingsTypes.FETCH_SETTINGS_SUCCESS:
      return action.payload.data;
    case settingsTypes.FETCH_SETTINGS_FAIL:
      return {
        ...state,
        ...null,
      };
    case settingsTypes.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationMessage: 'Settings saved',
        notificationVariant: 'success',
      };
    case settingsTypes.UPDATE_SETTINGS_FAIL:
      return {
        ...state,
        notificationMessage: action.payload,
        notificationVariant: 'error',
      };
    case uploadTypes.UPLOAD_SUCCESS:
      return {
        ...state,
        avatarUrl: action.payload.data[0].file.url,
        avatarFileId: action.payload.data[0].file.id,
      };
    case settingsTypes.RESET_SETTINGS_NOTIFICATION:
      return {
        ...state,
        notificationMessage: '',
      };
    default:
      return state;
  }
};
