import types from './types';
import { authenticationApi } from '../../constants/urls';

const authentication = ({ username, password }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'login',
        data: {
          identity: username,
          credential: password,
        },
      }),
    };
    const url = authenticationApi();
    const response = await fetch(url, options);

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(response.message);
    }
    await localStorage.setItem('displayName', JSON.stringify(username));
    await localStorage.setItem('token', JSON.stringify(data.data.sessionId));
    await localStorage.setItem('user', JSON.stringify(data.data.user));
    await localStorage.setItem('roles', JSON.stringify(data.data.roles));
    await dispatch({
      type: types.AUTHENTICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.AUTHENTICATION_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const requestNewPassword = ({ email, history }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'requestNewPassword',
        data: {
          email,
          callbackUrl: `${window.location.protocol}//localhost:3000/restore-password/`,
        },
      }),
    };
    const url = authenticationApi();
    const response = await fetch(url, options);

    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(response.message);
    }
    await dispatch({
      type: types.REQUEST_NEW_PASS_SUCCESS,
      payload: data,
    });
    history.push('/login');
  } catch (error) {
    dispatch({
      type: types.REQUEST_NEW_PASS_FAIL,
    });
  }
};

const status = () => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        token: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        method: 'status',
        data: {},
      }),
    };
    const url = authenticationApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(response.message);
    }
    await dispatch({
      type: types.STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.STATUS_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const onAppInit = () => async (dispatch) => {
  await dispatch(status());
};

const logOut = ({ history }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'logout',
        data: {},
      }),
    };
    const url = authenticationApi();
    const response = await fetch(url, options);

    if (response.status !== 200) {
      throw new Error(response.message);
    }
    await dispatch({
      type: types.LOG_OUT,
    });
    history.push('/login');
    await localStorage.clear();
  } catch (error) {
    dispatch({
      type: types.AUTHENTICATION_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

export default {
  authentication,
  requestNewPassword,
  status,
  onAppInit,
  logOut,
};
