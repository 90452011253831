import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import logoImg from '../../assets/images/coursio-gray.png';
import poweredByImg from '../../assets/images/Poweredby.png';
import { withSnackbar } from 'notistack';
import NotFound from '../notFound';
import Spinner from '../spinner';

import './styles.scss';

const INVITE_CODE_LENGTH = 7;

const BUSINESS_INVITE_STATUS = {
  UNKNOWN: 0,
  SENT_OUT: 1,
  ACCEPTED: 2,
  USER_CREATED: 4,
  FINALIZED: 8,
  TERMS_VIEWED: 16
};

class TermsConditions extends Component {
  constructor(props) {
    super(props);
    this.tcRef = React.createRef();
    this.state = {
      loading: false,
      textLink: ''
    }
  }

  async componentDidMount() {
    const { loadTerms, history } = this.props;
    
    try {
      await loadTerms(this.getCode());
      this.setState({
        loading: true
      });
    } catch (error) {
      console.log('error', error)
    } finally {
      this.setState({
        loading: false
      });

      const { termsData, status } = this.props;

      if(!status.error && (termsData.status & BUSINESS_INVITE_STATUS.ACCEPTED)){
        history.push(`/set-up/${this.getCode()}`)
      } 
    }
  }

  componentDidUpdate() {
    if(!this.state.textLink.length){
      const textLink = this.convertTermsToText();
      return this.setState({textLink: textLink})
    }
  }
  convertTermsToText = () => {
    const data = new Blob([this.tcRef.current.innerText], {type: 'text/plain'});
    return window.URL.createObjectURL(data)
  }

  getCode = () => {
    const { history } = this.props;
    return history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];
  };

  goToSetupPassword = async () => {
    const { history, acceptTerms, termsData } = this.props;
    await acceptTerms({ code: this.getCode(), version: termsData.version });
    history.push(`/set-up/${this.getCode()}`)
  };

  render() {
    const { termsData, status } = this.props;
    const dataIsEmpty = Object.keys(termsData).length === 0;
    
    if (this.getCode().length < INVITE_CODE_LENGTH) {
      return <NotFound />;
    }
    
    if (this.state.loading || (dataIsEmpty && !status.error) ) {
      return <Spinner />
    }

    return (
      <div className="tc-container">
        <div className="tc-container-header">
          <div className="logo-container">
            <img src={logoImg} alt="logo"/>
          </div>
          <Button
            download='coursio_terms_conditions.txt' 
            href={this.state.textLink}
            size="small"
            startIcon={<GetApp />}
          >
            Terms and Conditions
          </Button>
        </div>
        {status.error ?  
          <div className="tc-text" >{status.payload}</div> : 
          <> 
            <div className="tc-text">
              <div className="personal-info">
              <p><b>{termsData.name}</b> ({termsData.email})</p>
              </div>
              <div ref={this.tcRef} dangerouslySetInnerHTML={{__html: termsData && termsData.body}} />
            </div>
            <div className="accept-btn-container">
              <Button
                className="accept-btn"
                color="primary"
                onClick={this.goToSetupPassword}
              >
                Accept
              </Button>
            </div>
          </> 
        }
        <div className="tc-container-footer">
          <div className="logo-container">
            <img src={poweredByImg} alt="poweredBy"/>
            <img src={logoImg} alt="logo"/>
          </div>
        </div>
      </div>
    )
  }
}

export default withSnackbar(TermsConditions);
